/* Product Create, Vue Component */
<template>
  <v-card v-if="entity && !loading" id="product-create" class="entity-create">
      <v-card-title>Create Product</v-card-title>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-alert
          v-if="serverErrorMessage"
          type="error"
        >
          {{ serverErrorMessage }}
        </v-alert>
      </v-card-text>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-row class="align-center pb-5">
        <v-col>
        <v-btn
          color="primary"
          class="me-3"
          @click="Save"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiContentSaveOutline  }}
          </v-icon>
          <span>Save</span>
        </v-btn>
        </v-col>
        <v-col class="text-right">
        <v-btn
          color="secondary"
          class="me-3"
          @click="Back"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiChevronLeft }}
          </v-icon>
          <span>Back</span>
        </v-btn>
        </v-col>
      </v-row>
      </v-card-text>

      <v-form ref="form">
      <v-simple-table dense>
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>

      <tr>
        <td>Code</td>
        <td><v-text-field
      label="Code"
      solo-inverted
      flat
      :error-messages="serverErrors.filter(x => x.field === 'Code').map(x => x.error)"
      v-model="entity.Code"
      :rules="[validators.required]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>Name</td>
        <td><v-text-field
      label="Name"
      solo-inverted
      flat
      :error-messages="serverErrors.filter(x => x.field === 'Name').map(x => x.error)"
      v-model="entity.Name"
      :rules="[validators.required]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>Product Category</td>
        <td><ProductCategoryLookup v-model="entity.ProductCategoryId" :rules="[validators.requiredInteger]" :error-messages="serverErrors.filter(x => x.field === 'ProductCategoryId').map(x => x.error)" /></td>
      </tr>

      <tr>
        <td>Length</td>
        <td><v-text-field
      label="Length"
      solo-inverted
			type="number"
      flat
      suffix="mm"
      :error-messages="serverErrors.filter(x => x.field === 'Length').map(x => x.error)"
      v-model="entity.Length"
      :rules="[validators.requiredInteger,validators.positiveIntegerValidator,validators.integerRange(entity.Length, 0, 10000)]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>Width</td>
        <td><v-text-field
      label="Width"
      solo-inverted
			type="number"
      flat
      suffix="mm"
      :error-messages="serverErrors.filter(x => x.field === 'Width').map(x => x.error)"
      v-model="entity.Width"
      :rules="[validators.requiredInteger,validators.positiveIntegerValidator,validators.integerRange(entity.Width, 0, 10000)]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>Height</td>
        <td><v-text-field
      label="Height"
      solo-inverted
			type="number"
      flat
      suffix="mm"
      :error-messages="serverErrors.filter(x => x.field === 'Height').map(x => x.error)"
      v-model="entity.Height"
      :rules="[validators.requiredInteger,validators.positiveIntegerValidator,validators.integerRange(entity.Height, 0, 10000)]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>Price</td>
        <td><v-text-field
      label="Price"
      solo-inverted
			prefix="£"
			type="number"
      flat
      :error-messages="serverErrors.filter(x => x.field === 'Price').map(x => x.error)"
      v-model="entity.Price"
      :rules="[validators.requiredNumber]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>Example</td>
        <td><v-text-field
      label="Example"
      solo-inverted
      flat
      :error-messages="serverErrors.filter(x => x.field === 'Example').map(x => x.error)"
      v-model="entity.Example"
      :rules="[]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>TTLMD Code</td>
        <td><v-text-field
      label="TTLMD Code"
      solo-inverted
      flat
      :error-messages="serverErrors.filter(x => x.field === 'TTLMDProductCode').map(x => x.error)"
      v-model="entity.TTLMDProductCode"
      :rules="[validators.required]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>Volume Tolerance</td>
        <td><v-text-field
      label="Volume Tolerance"
      solo-inverted
			type="number"
      flat
      :error-messages="serverErrors.filter(x => x.field === 'VolumeTolerance').map(x => x.error)"
      v-model="entity.VolumeTolerance"
      :rules="[validators.requiredNumber]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>Dimension Tolerance</td>
        <td><v-text-field
      label="Dimension Tolerance"
      solo-inverted
			type="number"
      flat
      :error-messages="serverErrors.filter(x => x.field === 'DimensionTolerance').map(x => x.error)"
      v-model="entity.DimensionTolerance"
      :rules="[validators.requiredNumber]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>Order</td>
        <td><v-text-field
      label="Order"
      solo-inverted
			type="number"
      flat
      :error-messages="serverErrors.filter(x => x.field === 'SortOrder').map(x => x.error)"
      v-model="entity.SortOrder"
      :rules="[validators.requiredInteger,validators.integerValidator]"
    ></v-text-field>
</td>
      </tr>

      <tr>
        <td>Enabled</td>
        <td><v-checkbox v-model="entity.Enabled" :color="(entity.Enabled ? 'success' : 'secondary')"></v-checkbox></td>
      </tr>

      <tr>
        <td>Public</td>
        <td><v-checkbox v-model="entity.Public" :color="(entity.Public ? 'success' : 'secondary')"></v-checkbox></td>
      </tr>

      <tr>
        <td>Guesstimate</td>
        <td><v-checkbox v-model="entity.Guesstimate" :color="(entity.Guesstimate ? 'success' : 'secondary')"></v-checkbox></td>
      </tr>


        </tbody>
      </v-simple-table>
      </v-form>

  </v-card>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import * as Display from '@/datahelpers/displayTypes'
import * as FrameworkValidators from '@core/utils/validation'
import * as Validators from '@/datahelpers/validation'

import ProductCategoryLookup from '@/views/productcategory/ProductCategoryLookup'

import {
  mdiContentSaveOutline,
  mdiChevronLeft
} from '@mdi/js'
import { ref, watch, computed, expose } from '@vue/composition-api'

export default {
  components: {

    ProductCategoryLookup
  },
  setup() {

    const { route, router  } = useRouter();

    const entity = ref({
      SortOrder: 0,
		Created: null,
		Updated: null
    });
    const loading = ref(false);
    const form = ref(null);
    const serverErrors = ref([]);
    const serverErrorMessage = ref(null);

    const Save = function(e) {

      serverErrorMessage.value = null;
      serverErrors.value = [];

      const valid = form.value.validate();

      if (!valid) {
        return;
      }

      loading.value = true;

      store
        .dispatch('app/createProduct', {
          entity: entity.value
        })
        .then(response => {
          loading.value = false;
          router.push({ name: 'product-view', params: { id: response.data.Id } });
        })
        .catch((error) => {
          loading.value = false;
          if (error.error) {
            if (error.message) serverErrorMessage.value = error.message;
            if (error.errors) serverErrors.value = error.errors
          } else {
            console.log(error);
          }
        });
    };

    const Back = () => {
      router.go(-1);
    };

    const validators = {
      ...FrameworkValidators,
      ...Validators
    };

    return {
      Back,
      form,
      Save,
      loading,
      entity,
      Display,
      icons: {
        mdiContentSaveOutline ,
        mdiChevronLeft
      },
      validators,
      serverErrorMessage,
      serverErrors
    };
  }
}
</script>

<style lang="scss" scoped>
.entity-create::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td:last-child {
  padding-right: 0;
}
.entity-create::v-deep .v-data-table th:last-child {
  width: 66%;
}
.entity-create::v-deep  .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent;
}
.entity-create::v-deep .v-input:not(.error--text) .v-messages {
  min-height: unset;
}
.entity-create::v-deep .v-input .v-messages.error--text {
  margin-bottom: 8px;
}
.entity-create::v-deep .v-input:not(.error--text) .v-text-field__details {
  min-height: unset;
  margin-bottom: 0;
}
.entity-create::v-deep .v-input:not(.error--text) .v-input__slot {
  margin: 0;
  border-radius: 0;
}
.entity-create::v-deep .v-input--selection-controls {
  margin: 10px;
}
</style>

